<template>
  <div class="details">
    <div class="details__title px-5">
      <h1>{{ $t("trains.title") }}</h1>
    </div>

    <div class="details__content container">
      <div class="columns is-multiline details__content__box">
        <div
          class="field column is-one-third-desktop"
          v-if="
            companyInfo.userSettings?.canBookOnBehalfOfOthers &&
            companyInfo.allowListedEmployees &&
            businessUnits
          "
        >
          <h5 class="title is-5">
            {{ $t("TrainReservationForm.findEmployee") }}
          </h5>
          <EmployeeSelection
            :is-flights="true"
            :pre-selected-employees="guests"
            :business-units="businessUnits"
            @selected-employee="addEmployee"
          />
          <p v-if="companyInfo.allowListedEmployees">
            {{ $t("TrainReservationForm.unknownEmployee") }}
          </p>
        </div>

        <template
          v-if="
            !companyInfo?.userSettings?.canBookOnBehalfOfOthers ||
            (!companyInfo.allowUnlistedEmployees &&
              !companyInfo.allowListedEmployees)
          "
        >
          <div class="field column is-one-third-desktop">
            <h5 class="title is-5">
              {{ $t("TrainReservationForm.personalReservationHeader") }}
            </h5>
            <p>{{ $t("TrainReservationForm.personalReservation") }}</p>
          </div>
        </template>

        <template
          v-if="
            companyInfo.userSettings?.canBookOnBehalfOfOthers &&
            companyInfo.allowUnlistedEmployees
          "
        >
          <div class="field column is-one-third-desktop">
            <AddUnlistedEmployee
              :is-flights="true"
              @created-unlisted-employee="addUnlistedEmployee"
            />
          </div>
        </template>

        <div class="field column is-one-third-desktop">
          <template v-if="companyInfo.employeeIsRequired">
            <h5 class="title is-5">
              {{ $t("TrainReservationForm.selectedPassengers") }}
            </h5>
            <br />
            <i
              v-if="
                selectedEmployees.length == 0 &&
                selectedUnlistedEmployees.length == 0
              "
            >
              {{ $t("TrainReservationForm.noPassengersAdded") }}
            </i>
            <template v-for="e in selectedEmployees" :key="e">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <strong>{{ e.firstName }} {{ e.lastName }}</strong>
                <button
                  class="button is-small is-danger"
                  @click.stop.prevent="removeSelectedEmployee(e)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </template>
          </template>
          <template v-for="e in selectedUnlistedEmployees" :key="e">
            <div
              class="is-flex is-align-items-center is-justify-content-space-between mb-2"
            >
              <strong
                >{{ e.firstName }} {{ e.lastName }} ({{
                  e.emailAddress
                }})</strong
              >
              <button
                class="button is-small is-danger"
                @click.stop.prevent="removeUnlistedEmployees(e)"
              >
                <span class="icon is-small">
                  <i class="fas fa-times"></i>
                </span>
              </button>
            </div>
          </template>
          <template v-if="businessUnits?.length > 1">
            <h5 class="title is-6">
              {{ tbu("selectedBusinessUnit") }}
            </h5>
            <template v-if="selectedBusinessUnit">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <b>{{ selectedBusinessUnit.name }}</b>
                <button
                  class="button is-small is-warning"
                  @click.stop.prevent="showBusinessUnitSearch = true"
                >
                  <span class="icon is-small">
                    <i class="fas fa-edit"></i>
                  </span>
                </button>
                <button
                  class="button is-small is-danger"
                  v-if="!companyInfo.businessUnitMandatory"
                  @click.stop.prevent="removeBusinessUnit()"
                >
                  <span class="icon is-small">
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </template>
            <BusinessunitSelection
              v-if="showBusinessUnitSearch"
              :business-units="businessUnits"
              :preselected-business-unit-id="null"
              @selected-business-unit="selectBusinessUnit"
            />
          </template>
        </div>

        <div class="columns is-full-width details__content__box">
          <div class="column is-one-third">
            <h5 class="title is-5">
              {{ $t("TrainReservationForm.tripType") }}
            </h5>
            <div class="field">
              <label class="radio">
                <input
                  type="radio"
                  name="tripType"
                  value="oneWay"
                  v-model="tripType"
                />
                {{ $t("TrainReservationForm.oneWay") }}
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="tripType"
                  value="roundTrip"
                  v-model="tripType"
                />
                {{ $t("TrainReservationForm.return") }}
              </label>
            </div>
          </div>

          <div class="column is-one-third">
            <div class="field">
              <label class="label">{{ $t("TrainLegs.originStation") }}</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  v-model="searchTermOrigin"
                  :placeholder="$t('TrainLegs.originPlaceholder')"
                  autocomplete="off"
                />
                <div
                  v-for="station in filteredOriginStations"
                  :key="station.name"
                  class="dropdown-item"
                >
                  <span @click="selectOriginStation(station)">
                    {{ station.name }} - {{ station.city }},
                    {{ station.country }}
                  </span>
                </div>
              </div>
            </div>
            <Datepicker
              v-model="departureDateTrain"
              :enableTimePicker="false"
              autoApply
              :format="formatDate"
              :minDate="new Date()"
              locale="nl"
            />
          </div>

          <div class="column is-one-third">
            <div class="field">
              <label class="label">{{
                $t("TrainLegs.destinationStation")
              }}</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  v-model="searchTermDestination"
                  :placeholder="$t('TrainLegs.destinationPlaceholder')"
                  autocomplete="off"
                />
                <div
                  v-for="station in filteredDestinationStations"
                  :key="station.name"
                  class="dropdown-item"
                >
                  <span @click="selectDestinationStation(station)">
                    {{ station.name }} - {{ station.city }},
                    {{ station.country }}
                  </span>
                </div>
              </div>
            </div>
            <Datepicker
              v-model="returnDateTrain"
              :enableTimePicker="false"
              autoApply
              :format="formatDate"
              :minDate="departureDateTrain"
              locale="nl"
            />
          </div>
        </div>

        <form @submit.prevent="makeReservation()" class="details__content__box">
          <div class="columns is-multiline details__content__box">
            <div class="field column is-half-desktop">
              <label for="notes">{{ $t("TrainReservationForm.notes") }}</label>
              <div class="control">
                <textarea
                  class="textarea"
                  v-model="notes"
                  id="notes"
                  name="notes"
                  rows="5"
                  :placeholder="
                    $t('TrainReservationForm.trainNotesPlaceholder')
                  "
                ></textarea>
              </div>
              <template
                v-for="field in companyInfo.customFields"
                :key="field.name"
              >
                <CustomField
                  :customField="field"
                  @setted-value="setValue"
                ></CustomField>
              </template>
            </div>
            <div class="column">
              <h6 class="title is-6">
                {{ $t("TrainReservationForm.trainClass") }}
              </h6>
              <div class="field">
                <label>
                  <input type="radio" value="3" v-model="selectedTrainClass" />
                  {{ $t("TrainReservationForm.firstClass") }}
                </label>
              </div>
              <div class="field">
                <label>
                  <input type="radio" value="4" v-model="selectedTrainClass" />
                  {{ $t("TrainReservationForm.secondClass") }}
                </label>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" v-model="carRentalSelected" />
                  {{ $t("transportThankYouPage.CarRental") }}
                </label>
              </div>
            </div>
          </div>
          <div v-if="carRentalSelected" class="is-info mt-3">
            <div class="field mt-2">
              <label class="label">{{
                $t("transportThankYouPage.SelectCarClass")
              }}</label>
              <div class="control">
                <div class="select">
                  <select v-model="selectedCarClass">
                    <option
                      v-for="category in carCategories"
                      :key="category.value"
                      :value="category.value"
                    >
                      {{ category.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <h5 class="title is-5">
                {{ $t("ReservationForm.findDriver") }}
              </h5>
              <EmployeeSelection
                :business-units="businessUnits"
                @selected-employee="addEmployeeDriver"
              />
            </div>
            <div
              v-if="Driver.lastName"
              class="is-flex is-align-items-center is-justify-content-space-between mb-2"
            >
              <strong>{{ Driver.firstName }} {{ Driver.lastName }}</strong>
              <button
                class="button is-small is-danger"
                @click.stop.prevent="removeSelectedDriver"
              >
                <span class="icon is-small">
                  <i class="fas fa-times"></i>
                </span>
              </button>
            </div>
          </div>
          <button
            class="button is-primary mt-4"
            :disabled="disableButton"
            type="submit"
          >
            {{ $t("TrainReservationForm.sendRequest") }}
          </button>
        </form>
      </div>

      <NotificationMessage
        :level="notification.level"
        :autohide="false"
        :title="notification.title"
        :message="notification.message"
        :show="notification.show"
        @toggle="toggleNotification"
      />
    </div>
  </div>
</template>

<script>
import CommonHelper from "@/helpers/common.helper.js";
import BusinessunitSelection from "./components/reservationform/BusinessunitSelection.vue";
import EmployeeSelection from "./components/reservationform/EmployeeSelection.vue";
import AddUnlistedEmployee from "./components/reservationform/AddUnlistedEmployee.vue";
import CustomField from "./components/reservationform/CustomField.vue";
import trainStationsNL from "@/assets/trainstations.nl.json";
import trainStationsEN from "@/assets/trainstations.en.json";
import moment from "moment";

export default {
  components: {
    BusinessunitSelection,
    EmployeeSelection,
    AddUnlistedEmployee,
    CustomField
  },
  data() {
    return {
      selectedTrainClass: "economy",
      selectedHoldLuggage: false,
      changesAllowed: true,
      isLoading: false,
      legs: [],
      tripType: "roundTrip",
      isPricesLoading: false,
      showBusinessUnitSearch: true,
      disableButton: false,
      notes: "",
      guests: "",
      showEditableFields: false,
      returnedData: null,
      businessUnits: null,
      allowBusinessUnitClearing: false,
      selectedEmployees: [],
      selectedUnlistedEmployees: [],
      selectedBusinessUnit: null,
      errorMessages: [],
      companyInfo: {},
      reservationMeta: {
        businessUnit: null,
        location: null,
        dateRange: {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null
        }
      },
      carRentalSelected: false,
      carCategories: [
        {
          value: "MID_SIZE",
          label: this.$t("transportThankYouPage.carCategories.MiddelgroteAuto")
        },
        {
          value: "SMALL",
          label: this.$t("transportThankYouPage.carCategories.KleineAuto")
        },
        {
          value: "LARGE",
          label: this.$t("transportThankYouPage.carCategories.GroteAuto")
        },
        {
          value: "SUV",
          label: this.$t("transportThankYouPage.carCategories.SUV")
        },
        {
          value: "MINIVAN",
          label: this.$t("transportThankYouPage.carCategories.Minivan")
        },
        {
          value: "LUXURY",
          label: this.$t("transportThankYouPage.carCategories.LuxeAuto")
        },
        {
          value: "STATION",
          label: this.$t("transportThankYouPage.carCategories.Stationwagen")
        }
      ],
      Driver: {},
      departureDateTrain: null,
      returnDateTrain: null,
      customFieldFormData: {},
      isSaving: false,
      notification: {
        show: false,
        title: "",
        message: "",
        level: "info"
      },
      searchTermOrigin: "",
      searchTermDestination: "",
      trainStations: [],
      originStation: "",
      destinationStation: ""
    };
  },
  mounted() {
    this.trainStations =
      this.$i18n.locale === "nl" ? trainStationsNL : trainStationsEN;
    console.log(this.trainStations);
  },
  async created() {
    this.getCompanyInfo();

    let companyInfo = await CommonHelper.getCompanyInfo();
    if (companyInfo && companyInfo.budget) {
      this.budget = companyInfo.budget;
    }
  },
  computed: {
    numberOfPersons() {
      const selectedNumber =
        this.selectedEmployees.length + this.selectedUnlistedEmployees.length;
      return selectedNumber;
    },
    filteredOriginStations() {
      if (!this.searchTermOrigin) return [];

      return this.trainStations
        .filter(
          (station) =>
            (station.name &&
              station.name
                .toLowerCase()
                .includes(this.searchTermOrigin.toLowerCase())) ||
            (station.city &&
              station.city
                .toLowerCase()
                .includes(this.searchTermOrigin.toLowerCase())) ||
            (station.country &&
              station.country
                .toLowerCase()
                .includes(this.searchTermOrigin.toLowerCase()))
        )
        .slice(0, 10);
    },

    filteredDestinationStations() {
      if (!this.searchTermDestination) return [];

      return this.trainStations
        .filter(
          (station) =>
            (station.name &&
              station.name
                .toLowerCase()
                .includes(this.searchTermDestination.toLowerCase())) ||
            (station.city &&
              station.city
                .toLowerCase()
                .includes(this.searchTermDestination.toLowerCase())) ||
            (station.country &&
              station.country
                .toLowerCase()
                .includes(this.searchTermDestination.toLowerCase()))
        )
        .slice(0, 10);
    }
  },
  methods: {
    loadDetails() {},
    setValue(data) {
      this.customFieldFormData[data.field] = data.value;
    },
    async getCompanyInfo() {
      this.companyInfo = await CommonHelper.getCompanyInfo();

      this.companyInfo.employeeIsRequired =
        this.companyInfo.userSettings.canBookOnBehalfOfOthers &&
        (this.companyInfo.allowUnlistedEmployees ||
          this.companyInfo.allowListedEmployees);

      this.companyInfo.customFields.sort((a, b) =>
        Number(a.mandatory) > Number(b.mandatory) ? -1 : 1
      );

      this.businessUnits = await CommonHelper.getBusinessUnits();
    },
    selectBusinessUnit(data) {
      this.selectedBusinessUnit = data.businessUnit;
      this.showBusinessUnitSearch = false;
    },
    removeBusinessUnit() {
      this.selectedBusinessUnit = null;
      this.showBusinessUnitSearch = true;
    },
    addEmployee(data) {
      const employeeAlreadySelected = this.selectedEmployees.find(
        (sE) => sE.id == data.id
      );

      if (!employeeAlreadySelected) {
        this.selectedEmployees.push(data.employee);

        if (this.selectedBusinessUnit == null && data.businessUnit) {
          this.selectBusinessUnit(data);
        }
      }
    },
    addEmployeeDriver(data) {
      if (data?.employee) {
        this.Driver = {
          id: data.employee.id,
          firstName: data.employee.firstName,
          lastName: data.employee.lastName,
          emailAddress: data.employee.emailAddress
        };
      } else {
        this.driverEmployee = null;
      }
    },
    removeSelectedDriver() {
      this.Driver = {};
    },
    removeSelectedEmployee(e) {
      this.selectedEmployees = this.selectedEmployees.filter(
        (se) => se.id != e.id
      );
    },
    addUnlistedEmployee(data) {
      this.selectedUnlistedEmployees.push(data.employee);
    },
    removeUnlistedEmployees(e) {
      this.selectedUnlistedEmployees = this.selectedUnlistedEmployees.filter(
        (uE) => uE.id != e.id
      );
    },
    async makeReservation() {
      let url = `${this.AVAILABILITY_API_URL}/reservation/train`;
      let dataIsValid = this.validateForm();

      if (dataIsValid) {
        this.isSaving = true;
        let notes = this.notes;
        const employees = this.selectedEmployees.map((e) => e.id);
        const unlistedEmployees = this.selectedUnlistedEmployees.map((e) => {
          return {
            firstName: e.firstName,
            lastName: e.lastName,
            emailAddress: e.emailAddress
          };
        });
        const businessUnitId = this.selectedBusinessUnit
          ? this.selectedBusinessUnit.id
          : null;

        let reservationList = [
          {
            customFields: this.customFieldFormData,
            travelClass: this.selectedTrainClass,
            businessUnitId: businessUnitId,
            trainStationFrom: this.searchTermOrigin,
            trainStationTo: this.searchTermDestination,
            notes: notes,
            employeeIds: employees,
            guests: unlistedEmployees,
            vehicleType: 1,
            trainDepartureDate: moment(this.departureDateTrain).format(
              "YYYY-MM-DD"
            ),
            trainReturnDate: moment(this.returnDateTrain).format("YYYY-MM-DD")
          }
        ];

        if (this.carRentalSelected) {
          reservationList.push({
            carPickupDate: moment(this.departureDateTrain).format("YYYY-MM-DD"),
            carReturnDate: moment(this.returnDateTrain).format("YYYY-MM-DD"),
            customFields: this.customFieldFormData,
            carClass: this.selectedCarClass,
            businessUnitId: businessUnitId,
            Driver: this.Driver,
            vehicleType: "RENTAL_CAR"
          });
        }

        let saveData = {
          reservationList: reservationList
        };

        Object.keys(this.customFieldFormData).forEach((key) => {
          saveData.reservationList[0].customFields[key] =
            this.customFieldFormData[key];
        });

        this.saveData = JSON.stringify(saveData, null, " ");
        this.disableButton = true;

        console.log(saveData);

        const { status, data } = await this.axios.post(url, saveData);

        if (status == 200) {
          this.notification.title = this.$t("TrainReservationForm.savedTitle");
          this.notification.message = this.$t("success");
          this.notification.show = true;
          this.notification.level = "success";

          setTimeout(() => {
            this.notification.show = false;
            this.disableButton = false;
          }, 30 * 1000);

          let unlistedEmployeesCount = this.selectedUnlistedEmployees.length;
          let listedEmployeesCount = this.selectedEmployees.length;
          let employeeCount = unlistedEmployeesCount + listedEmployeesCount;

          // Switch to "trainReservation" for analytics, if that's purely internal
          // and does not conflict with an external system.
          let trackData = {
            reservationType: "train",
            departureStations: this.legs
              .map((l) => l.departureAirport.city)
              .join(", "),
            arrivalStations: this.legs
              .map((l) => l.arrivalAirport.city)
              .join(", "),
            legs: this.legs.length,
            numberOfPersons: employeeCount,
            status: "success"
          };
          this.track("trainReservation", trackData);

          this.$router.push("/train-confirmation");
        } else {
          this.notification.title = this.$t("TrainReservationForm.errorTitle");
          this.notification.message = this.$t(
            "TrainReservationForm.errorMessage"
          );
          this.notification.show = true;
          this.notification.level = "danger";
          this.disableButton = false;

          console.log;

          setTimeout(() => {
            this.notification.show = false;
          }, 30 * 1000);
        }
        this.isSaving = false;
      }
    },
    validateForm() {
      this.errorMessages = [];
      this.showNotification = false;

      const businessUnitMandatory = this.companyInfo.businessUnitMandatory;
      const businessUnitIsSelected = !!this.selectedBusinessUnit;
      const employeeIsRequired = this.companyInfo.employeeIsRequired;
      const employeeIsSelected =
        this.selectedUnlistedEmployees.length > 0 ||
        this.selectedEmployees.length > 0;

      let isValid = true;

      if (businessUnitMandatory && !businessUnitIsSelected) {
        isValid = false;
        this.errorMessages.push(this.$t("error.businessunitMandatory"));
      }

      if (employeeIsRequired && !employeeIsSelected) {
        isValid = false;
        this.errorMessages.push(this.$t("error.employeeMandatory"));
      }

      if (!this.searchTermOrigin || this.searchTermOrigin.trim() === "") {
        this.errorMessages.push(this.$t("error.departureStation"));
        isValid = false;
      }

      if (!this.departureDateTrain) {
        this.errorMessages.push(this.$t("error.departureDate"));
        isValid = false;
      }

      if (this.tripType === "roundTrip") {
        if (
          !this.searchTermDestination ||
          this.searchTermDestination.trim() === ""
        ) {
          this.errorMessages.push(this.$t("error.destinationStation"));
          isValid = false;
        }

        if (!this.returnDateTrain) {
          this.errorMessages.push(this.$t("error.returnDate"));
          isValid = false;
        } else if (this.returnDateTrain < this.departureDateTrain) {
          this.errorMessages.push(this.$t("error.dateBefore"));
          isValid = false;
        }
      }

      let customFieldsValid = true;
      let invalidCustomFields = [];

      this.companyInfo.customFields.forEach((field) => {
        if (field.mandatory) {
          if (
            !this.customFieldFormData[field.name] ||
            this.customFieldFormData[field.name] === ""
          ) {
            customFieldsValid = false;
            isValid = false;
            invalidCustomFields.push(this.getTitle(field));
          }
        }
      });

      if (!customFieldsValid) {
        let message = this.$t("error.customFields");
        message += ` (${invalidCustomFields.join(", ")})`;
        this.errorMessages.push(message);
      }

      if (!isValid) {
        let notificationMessage = `${this.$t("error.genericIntro")} <ul>
      <li>- ${this.errorMessages.join("</li><li>- ")}</li></ul>`;

        this.notification.title = this.$t("error.genericTitle");
        this.notification.message = notificationMessage;
        this.notification.level = "danger";
        this.notification.show = true;

        setTimeout(() => {
          this.notification.show = false;
        }, 10 * 1000);
        return false;
      } else {
        return true;
      }
    },
    getTitle(field) {
      return this.getFieldNameTranslation(field);
    },
    toggleNotification() {
      this.notification.show = !this.notification.show;
    },
    track(eventName, data) {
      // your tracking implementation
      // e.g. console.log("Tracking:", eventName, data);
    },
    getFieldNameTranslation(field) {
      // your custom method if needed
      return field.label || field.name;
    },
    selectOriginStation(station) {
      this.originStation = `${station.name}, ${station.city}`;
      this.searchTermOrigin = `${station.name}, ${station.city}`;
    },
    selectDestinationStation(station) {
      this.destinationStation = `${station.name}, ${station.city}`;
      this.searchTermDestination = `${station.name}, ${station.city}`;
    }
  }
};
</script>
<style scoped>
.details__title {
  background: url("@/assets/images/train-bg.webp") center center no-repeat;
  background-size: cover;
}

.dropdown-item {
  cursor: pointer;
  padding: 5px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
</style>
