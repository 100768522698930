<template>
  <div class="details">
    <div class="details__title px-5">
      <h1>{{ $t("flights.title") }}</h1>
    </div>

    <div class="details__content container">
      <div class="columns is-multiline details__content__box">
        <div
          class="field column is-one-third-desktop"
          v-if="
            companyInfo.userSettings?.canBookOnBehalfOfOthers &&
            companyInfo.allowListedEmployees &&
            businessUnits
          "
        >
          <h5 class="title is-5">{{ $t("ReservationForm.findEmployee") }}</h5>

          <EmployeeSelection
            :is-flights="true"
            :pre-selected-employees="guests"
            :business-units="businessUnits"
            :selected-employees="selectedEmployees"
            @selected-employee="addEmployee"
          />
          <p v-if="companyInfo.allowListedEmployees">
            {{ $t("ReservationForm.unknownEmployee") }}
          </p>
        </div>
        <template
          v-if="
            !companyInfo?.userSettings?.canBookOnBehalfOfOthers ||
            (!companyInfo.allowUnlistedEmployees &&
              !companyInfo.allowListedEmployees)
          "
        >
          <div class="field column is-one-third-desktop">
            <h5 class="title is-5">
              {{ $t("ReservationForm.personalReservationHeader") }}
            </h5>

            <p>{{ $t("ReservationForm.personalReservation") }}</p>
          </div>
        </template>

        <template
          v-if="
            companyInfo.userSettings?.canBookOnBehalfOfOthers &&
            companyInfo.allowUnlistedEmployees
          "
        >
          <div class="field column is-one-third-desktop">
            <AddUnlistedEmployee
              :is-flights="true"
              @created-unlisted-employee="addUnlistedEmployee"
            />
          </div>
        </template>

        <div class="field column is-one-third-desktop">
          <template v-if="companyInfo.employeeIsRequired">
            <h5 class="title is-5">
              {{ $t("ReservationForm.selectedPassengers") }}
            </h5>

            <br />
            <i
              v-if="
                selectedEmployees.length == 0 &&
                selectedUnlistedEmployees.length == 0
              "
            >
              {{ $t("ReservationForm.noPassengersAdded") }}</i
            >
            <template v-for="e in selectedEmployees" :key="e">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <strong>{{ e.firstName }} {{ e.lastName }}</strong>
                <button class="button is-small is-danger">
                  <span
                    class="icon is-small is-danger"
                    @click.stop.prevent="removeSelectedEmployee(e)"
                  >
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </template>
            <br />
            <br />
          </template>
          <template v-for="e in selectedUnlistedEmployees" :key="e">
            <div
              class="is-flex is-align-items-center is-justify-content-space-between mb-2"
            >
              <strong
                >{{ e.firstName }} {{ e.lastName }} ({{
                  e.emailAddress
                }})</strong
              >
              <button class="button is-small is-danger">
                <span
                  class="icon is-small is-danger"
                  @click.stop.prevent="removeUnlistedEmployees(e)"
                >
                  <i class="fas fa-times"></i>
                </span>
              </button>
            </div>
            <br />
            <br />
          </template>
          <template v-if="businessUnits?.length > 1">
            <h5 class="title is-6">
              {{ tbu("selectedBusinessUnit") }}
            </h5>
            <br />
            <template v-if="selectedBusinessUnit">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <b> {{ selectedBusinessUnit.name }}</b>
                <button class="button is-small is-warning">
                  <span
                    class="icon is-small"
                    @click.stop.prevent="showBusinessUnitSearch = true"
                  >
                    <i class="fas fa-edit"></i>
                  </span>
                </button>
                <button
                  class="button is-small is-danger"
                  v-if="!companyInfo.businessUnitMandatory"
                >
                  <span
                    class="icon is-small is-danger"
                    @click.stop.prevent="removeBusinessUnit()"
                  >
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </template>
            <BusinessunitSelection
              v-if="showBusinessUnitSearch"
              :business-units="businessUnits"
              :preselected-business-unit-id="null"
              @selected-business-unit="selectBusinessUnit"
            />
          </template>
        </div>
      </div>
      <div class="columns is-full-width details__content__box">
        <div
          v-for="type in roundTripTypes"
          class="column is-one-quarter"
          :key="type"
        >
          <label>
            <input
              type="radio"
              :value="type.value"
              v-model="selectedRoundTripType"
            />
            {{ type.label }}
          </label>
        </div>
      </div>
      <FlightLegs
        @legs-selected="selectedLegs"
        :selectedRoundtripType="selectedRoundTripType"
      />
      <form @submit.prevent="makeReservation()">
        <div class="columns is-multiline details__content__box mb-5">
          <div class="field column is-half-desktop">
            <label for="notes">{{ $t("ReservationForm.notes") }} </label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="notes"
                id="notes"
                name="notes"
                type="text"
                rows="5"
                :placeholder="$t('ReservationForm.flightNotesPlaceholder')"
              ></textarea>
            </div>
            <template
              v-for="field in companyInfo.customFields"
              :key="field.name"
            >
              <CustomField
                :customField="field"
                @setted-value="setValue"
              ></CustomField>
            </template>
          </div>
          <div class="column">
            <h6 class="title is-6">{{ $t("ReservationForm.flightClass") }}</h6>

            <br />
            <div v-for="flightClass in flightClasses" :key="flightClass.value">
              <label>
                <input
                  type="radio"
                  :value="flightClass.value"
                  v-model="selectedFlightClass"
                />
                {{ flightClass.label }}
              </label>
            </div>

            <br />
            <label class="checkbox">
              <input type="checkbox" v-model="selectedHoldLuggage" />

              {{ $t("ReservationForm.checkedLuggage") }}
            </label>
            <br />
            <label class="checkbox">
              <input type="checkbox" v-model="changesAllowed" />

              {{ $t("ReservationForm.changesAllowed") }}
            </label>

            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="carRentalSelected" />
                {{ $t("transportThankYouPage.CarRental") }}
              </label>
            </div>

            <div v-if="carRentalSelected" class="is-info mt-3">
              <div class="field mt-2">
                <label class="label">{{
                  $t("transportThankYouPage.SelectCarClass")
                }}</label>
                <div class="control">
                  <div class="select">
                    <select v-model="selectedCarClass">
                      <option
                        v-for="category in carCategories"
                        :key="category.value"
                        :value="category.value"
                      >
                        {{ category.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <h5 class="title is-5">
                  {{ $t("ReservationForm.findDriver") }}
                </h5>
                <EmployeeSelection
                  :business-units="businessUnits"
                  @selected-employee="addEmployeeDriver"
                />
              </div>
              <div
                v-if="Driver.lastName"
                class="is-flex is-align-items-center is-justify-content-space-between mb-2"
              >
                <strong>{{ Driver.firstName }} {{ Driver.lastName }}</strong>
                <button
                  class="button is-small is-danger"
                  @click.stop.prevent="removeSelectedDriver"
                >
                  <span class="icon is-small">
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </div>
            <br /><br />
            <button
              class="button is-primary"
              :disabled="disableButton"
              type="submit"
            >
              {{ $t("sendRequest") }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <NotificationMessage
      :level="notification.level"
      :autohide="false"
      :title="notification.title"
      :message="notification.message"
      :show="notification.show"
      @toggle="toggleNotification"
    />
  </div>
</template>
<script>
import CommonHelper from "@/helpers/common.helper.js";

import SelectOffer from "./components/hoteldetail/SelectOffer.vue";
import BusinessunitSelection from "./components/reservationform/BusinessunitSelection.vue";
import EmployeeSelection from "./components/reservationform/EmployeeSelection.vue";
import AddUnlistedEmployee from "./components/reservationform/AddUnlistedEmployee.vue";
import CustomField from "./components/reservationform/CustomField.vue";
import FlightLegs from "./components/reservationform/FlightLegs.vue";

export default {
  components: {
    // eslint-disable-next-line
    SelectOffer,
    FlightLegs,
    BusinessunitSelection,
    EmployeeSelection,
    AddUnlistedEmployee,
    CustomField
  },
  data() {
    return {
      selectedFlightClass: "economy",
      selectedHoldLuggage: false,
      changesAllowed: true,
      isLoading: false,
      legs: [],
      selectedRoundTripType: "roundTrip",
      isPricesLoading: false,
      showBusinessUnitSearch: true,
      disableButton: false,
      notes: "",
      guests: "",
      showEditableFields: false,
      returnedData: null,
      businessUnits: null,
      allowBusinessUnitClearing: false,
      selectedEmployees: [],
      selectedUnlistedEmployees: [],
      selectedBusinessUnit: null,
      errorMessages: [],
      companyInfo: {},
      reservationMeta: {
        businessUnit: null,
        location: null,
        dateRange: {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null
        }
      },
      carRentalSelected: false,
      selectedCarClass: "",
      carCategories: [
        {
          value: "MID_SIZE",
          label: this.$t("transportThankYouPage.carCategories.MiddelgroteAuto")
        },
        {
          value: "SMALL",
          label: this.$t("transportThankYouPage.carCategories.KleineAuto")
        },
        {
          value: "LARGE",
          label: this.$t("transportThankYouPage.carCategories.GroteAuto")
        },
        {
          value: "SUV",
          label: this.$t("transportThankYouPage.carCategories.SUV")
        },
        {
          value: "MINIVAN",
          label: this.$t("transportThankYouPage.carCategories.Minivan")
        },
        {
          value: "LUXURY",
          label: this.$t("transportThankYouPage.carCategories.LuxeAuto")
        },
        {
          value: "STATION",
          label: this.$t("transportThankYouPage.carCategories.Stationwagen")
        }
      ],
      Driver: {},
      customFieldFormData: {},
      isSaving: false,
      roundTripTypes: [
        { value: "oneWay", label: this.$t("ReservationForm.oneWay") },
        { value: "roundTrip", label: this.$t("ReservationForm.roundTrip") },
        { value: "multiLeg", label: this.$t("ReservationForm.multiLeg") }
      ],
      flightClasses: [
        { value: "economy", label: this.$t("ReservationForm.economy") },
        {
          value: "PREMIUM_ECONOMY",
          label: this.$t("ReservationForm.PREMIUM_ECONOMY")
        },
        { value: "business", label: this.$t("ReservationForm.business") }
      ]
    };
  },
  async created() {
    this.getCompanyInfo();

    let companyInfo = await CommonHelper.getCompanyInfo();
    if (companyInfo && companyInfo.budget) {
      this.budget = companyInfo.budget;
    }

    this.loadDetails();
  },

  computed: {
    numberOfPersons() {
      const selectedNumber =
        this.selectedEmployees.length + this.selectedUnlistedEmployees.length;
      return selectedNumber;
    }
  },
  methods: {
    loadDetails() {},
    selectedLegs(legs) {
      this.legs = legs;
    },
    setValue(data) {
      this.customFieldFormData[data.field] = data.value;
    },
    async getCompanyInfo() {
      this.companyInfo = await CommonHelper.getCompanyInfo();

      this.companyInfo.employeeIsRequired =
        this.companyInfo.userSettings.canBookOnBehalfOfOthers &&
        (this.companyInfo.allowUnlistedEmployees ||
          this.companyInfo.allowListedEmployees);

      this.companyInfo.customFields.sort((a, b) => {
        return Number(a.mandatory) > Number(b.mandatory) ? -1 : 1;
      });

      this.businessUnits = await CommonHelper.getBusinessUnits();
    },
    selectBusinessUnit(data) {
      this.selectedBusinessUnit = data.businessUnit;
      this.showBusinessUnitSearch = false;
    },
    removeBusinessUnit() {
      this.selectedBusinessUnit = null;
      this.showBusinessUnitSearch = true;
    },
    addEmployee(data) {
      const employeeAlreadySelected = this.selectedEmployees.find(
        (sE) => sE.id == data.id
      );

      if (!employeeAlreadySelected) {
        this.selectedEmployees.push(data.employee);

        if (this.selectedBusinessUnit == null && data.businessUnit) {
          this.selectBusinessUnit(data);
        }
      }
    },
    addEmployeeDriver(data) {
      if (data?.employee) {
        this.Driver = {
          id: data.employee.id,
          firstName: data.employee.firstName,
          lastName: data.employee.lastName,
          emailAddress: data.employee.emailAddress
        };
      } else {
        this.driverEmployee = null;
      }
    },
    removeSelectedDriver() {
      this.Driver = {};
    },
    removeSelectedEmployee(e) {
      this.selectedEmployees = this.selectedEmployees.filter(
        (se) => se.id != e.id
      );
    },
    addUnlistedEmployee(data) {
      this.selectedUnlistedEmployees.push(data.employee);
    },
    removeUnlistedEmployees(e) {
      this.selectedUnlistedEmployees = this.selectedUnlistedEmployees.filter(
        (uE) => uE.id != e.id
      );
    },
    async makeReservation() {
      // eslint-disable-next-line no-undef
      let url = `${this.AVAILABILITY_API_URL}/reservation/flight`;
      let saveObject = {};
      saveObject.legs = this.legs;
      let dataIsValid = this.validateForm();

      if (dataIsValid) {
        this.isSaving = true;
        let notes = this.notes;
        const employees = this.selectedEmployees.map((e) => e.id);
        const unlistedEmployees = this.selectedUnlistedEmployees.map((e) => {
          return {
            firstName: e.firstName,
            lastName: e.lastName,
            emailAddress: e.emailAddress
          };
        });
        const businessUnitId = this.selectedBusinessUnit
          ? this.selectedBusinessUnit.id
          : null;

        let reservationList = [
          {
            customFields: this.customFieldFormData,
            changesAllowed: this.changesAllowed,
            travelClass: this.selectedFlightClass,
            holdLuggage: this.selectedHoldLuggage,
            businessUnitId: businessUnitId,
            flights: this.legs,
            notes: notes,
            employeeIds: employees,
            guests: unlistedEmployees,
            vehicleType: 0
          }
        ];

        if (this.carRentalSelected) {
          const rentalObj = {
            carPickupDate: this.legs[0].departureDate,
            carPickupTime: this.legs[0].dayPart,
            customFields: this.customFieldFormData,
            carClass: this.selectedCarClass,
            Driver: this.Driver,
            businessUnitId: businessUnitId,
            vehicleType: "RENTAL_CAR"
          };

          if (this.legs.length > 1) {
            rentalObj.carReturnDate = this.legs[1].departureDate;
            rentalObj.carReturnTime = this.legs[1].dayPart;
          }

          reservationList.push(rentalObj);
        }

        let saveData = {
          reservationList: reservationList
        };

        Object.keys(this.customFieldFormData).forEach((key) => {
          saveData.reservationList[0].customFields[key] =
            this.customFieldFormData[key];
        });

        this.saveData = JSON.stringify(saveData, null, " ");

        this.disableButton = true;
        const { status, data } = await this.axios.post(url, saveData);

        if (status == 200) {
          this.notification.title = this.$t("ReservationForm.savedTitle");
          this.notification.message = this.$t("ReservationForm.savedMessage");
          this.notification.show = true;
          this.notification.level = "success";
          setTimeout(() => {
            this.notification.show = false;
            this.disableButton = false;
          }, 30 * 1000);
          let unlistedEmployeesCount = this.selectedUnlistedEmployees.length;
          let listedEmployeesCount = this.selectedEmployees.length;
          let employeeCount = unlistedEmployeesCount + listedEmployeesCount;
          let trackData = {
            reservationType: "flight",
            departureAirports: this.legs
              .map((l) => l.departureAirport.city)
              .join(", "),
            arrivalAirports: this.legs
              .map((l) => l.arrivalAirport.city)
              .join(", "),
            legs: this.legs.length,
            numberOfPersons: employeeCount,
            status: "success"
          };
          this.track("flightReservation", trackData);
          this.$router.push("/flight-confirmation");
        } else {
          this.notification.title = this.$t("ReservationForm.errorTitle");
          this.notification.message = this.$t("ReservationForm.errorMessage");
          this.notification.show = true;
          this.notification.level = "danger";
          this.disableButton = false;
          setTimeout(() => {
            this.notification.show = false;
          }, 30 * 1000);
        }
      }

      this.isSaving = false;
    },
    validateForm() {
      this.errorMessages = [];
      this.showNotification = false;

      const businessUnitMandatory = this.companyInfo.businessUnitMandatory;
      const businessUnitIsSelected = !!this.selectedBusinessUnit;

      const employeeIsRequired = this.companyInfo.employeeIsRequired;

      let employeeIsSelected =
        this.selectedUnlistedEmployees.length > 0 ||
        this.selectedEmployees.length > 0;
      let isValid = true;

      if (businessUnitMandatory && !businessUnitIsSelected) {
        isValid = false;
        let message = this.$t("error.businessunitMandatory");
        message = this.tbu(message);
        this.errorMessages.push(message);
      }
      if (employeeIsRequired && !employeeIsSelected) {
        isValid = false;
        const message = this.$t("error.employeeMandatory");
        this.errorMessages.push(message);
      }

      if (this.legs.length < 1) {
        isValid = false;
        const message = this.$t("error.noLegs");
        this.errorMessages.push(message);
      }

      let customFieldsValid = true;
      let invalidCustomFields = [];
      this.companyInfo.customFields.forEach((field) => {
        if (field.mandatory) {
          let thisFieldHasError = false;
          if (field.type === "checkbox") {
            if (
              !this.customFieldFormData[field.name] ||
              this.customFieldFormData[field.name].length < 1
            ) {
              thisFieldHasError = true;
            }
          } else {
            if (
              !this.customFieldFormData[field.name] ||
              this.customFieldFormData[field.name] == ""
            ) {
              thisFieldHasError = true;
            }
          }
          if (thisFieldHasError) {
            customFieldsValid = false;
            isValid = false;
            invalidCustomFields.push(this.getTitle(field));
          }
        }
      });

      if (!customFieldsValid) {
        let message = this.$t("error.customFields");
        message += ` (${invalidCustomFields.join(", ")})`;
        this.errorMessages.push(message);
      }

      if (!isValid) {
        let notificationMessage = `${this.$t("error.genericIntro")} <ul>
        <li>- ${this.errorMessages.join("</li><li>- ")}</li></ul>`;

        this.track("hotelReservationErrors", {
          error: this.errorMessages.join(", ")
        });

        this.notification.title = this.$t("error.genericTitle");
        this.notification.message = notificationMessage;
        this.notification.level = "danger";
        this.notification.show = true;

        setTimeout(() => {
          this.notification.show = false;
        }, 10 * 1000);
        return false;
      } else {
        return true;
      }
    },
    getTitle(field) {
      return this.getFieldNameTranslation(field);
    }
  }
};
</script>
<style scoped>
.details__title {
  background: url("@/assets/images/flight-bg.webp") center center no-repeat;
  background-size: cover;
}
</style>
