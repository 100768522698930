import axios from "axios";
import CommonHelper from "@/helpers/common.helper.js";

const getReservation = async () => {
  const url = `${CommonHelper.getApiURL()}/reservation/getreservationfromsession`;

  try {
    const { data, status } = await axios.get(url);

    if (status === 200 && data) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching reservation:", error);
  }

  return null;
};

const getTransport = async () => {
  const url = `${CommonHelper.getApiURL()}/reservation/gettransportfromsession`;

  try {
    const { data, status } = await axios.get(url);

    console.log(data);

    if (status === 200 && data) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching reservation:", error);
  }

  return null;
};

export default {
  getReservation,
  getTransport
};
