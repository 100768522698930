<template>
  <div class="details reservationoverview">
    <div class="details__title px-5" v-if="reservation">
      <h1>{{ $t("thankYouPage.PageHeading") }}</h1>
    </div>
    <div class="container mt-6">
      <div class="columns is-multiline">
        <div class="column is-full has-text-centered" v-if="error">
          <p class="has-text-danger">{{ error }}</p>
        </div>

        <div class="column is-full">
          <br />
          <div class="column is-full">
            <div class="section full-width">
              <h2 class="section-title">
                📩 {{ $t("thankYouPage.InfoHeading") }}
              </h2>
              <p>
                {{ $t("thankYouPage.InfoParagraph1") }}
              </p>
              <br />
              <p>{{ $t("thankYouPage.InfoParagraph2", { email: email }) }}</p>
              <br />
              <ul class="booking-links">
                <li>
                  <a href="/">🏨 {{ $t("thankYouPage.HotelsHrefs") }}</a>
                </li>
                <li v-if="enableFlightRequests">
                  <a href="/flightform"
                    >✈️ {{ $t("thankYouPage.FlightsHrefs") }}</a
                  >
                </li>
                <li v-if="enableTrainRequests">
                  <a href="/trainform"
                    >🚆 {{ $t("transportThankYouPage.TrainHref") }}</a
                  >
                </li>
                <li v-if="enableMeetingRequests">
                  <a href="/meetingroomreservation"
                    >🏢 {{ $t("thankYouPage.MeetingHref") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="column is-full has-text-centered">
          <a href="/reservation-overview" class="complete-trip-button">
            ✅ {{ $t("thankYouPage.CompleteBooking") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationHelper from "@/helpers/reservation.overview.helper";
import CommonHelper from "@/helpers/common.helper.js";
import moment from "moment";

export default {
  data() {
    return {
      reservation: null,
      isLoading: true,
      error: null,
      email: "zakelijk@lookingforbooking.com",
      enableMeetingRequests: false,
      enableFlightRequests: false,
      enableTrainRequests: false
    };
  },
  watch: {
    $route() {
      this.checkLoginStatus();
    }
  },
  async created() {
    moment.locale(this.languageIso);
    await this.checkLoginStatus();
    if (this.isLoggedIn) {
      this.injectCustomCss();
      let companyInfo = await CommonHelper.getCompanyInfo();
      this.fullName = companyInfo?.fullName ?? "";
      this.enableMeetingRequests = companyInfo?.enableMeetingRequests ?? true;
      this.enableFlightRequests = companyInfo?.enableFlightRequests ?? true;
      this.enableTrainRequests = companyInfo?.enableTrainRequests ?? true;
    }
  },
  methods: {
    async checkLoginStatus() {
      let userData = CommonHelper.isLoggedIn();
      if (userData) {
        this.isLoggedIn = true;
        this.userName = userData.userName;
        let companyInfo = await CommonHelper.getCompanyInfo();

        this.enableMeetingRequests = companyInfo.enableMeetingRequests ?? true;
        this.enableFlightRequests = companyInfo.enableFlightRequests ?? true;
        this.enableTrainRequests = companyInfo.enableTrainRequests ?? true;
      } else {
        this.isLoggedIn = false;
      }
    }
  }
};
</script>

<style scoped>
.details__title {
  background: url("@/assets/images/meetingroom.jpeg") center center no-repeat;
  background-size: cover;
  text-align: center;
  padding: 40px 20px;
  color: white;
  border-radius: 10px;
}

.details__title h1 {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.details__title .subtitle {
  font-size: 18px;
  color: #ddd;
}

.details.reservationoverview {
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: start;
}

.full-width {
  grid-column: span 2;
}

.section {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.section:hover {
  transform: translateY(-2px);
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

.details__content__box p {
  font-size: 16px;
  color: #444;
  line-height: 1.6;
  margin-bottom: 8px;
}

.details__content__box strong {
  color: #222;
  font-weight: 600;
}

@media (max-width: 768px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    width: 100%;
  }

  .section {
    width: 92%;
    max-width: 400px;
    margin: 0 auto;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .details__content__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .full-width {
    width: 92%;
    max-width: 400px;
    margin: 0 auto 10px auto;
  }

  .details__title {
    padding: 20px 10px;
  }

  .details__title h1 {
    font-size: 22px;
  }

  .section-title {
    font-size: 18px;
  }

  .details__content__box p {
    font-size: 14px;
  }
}
.booking-links {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.booking-links li {
  margin-bottom: 8px;
}
.booking-links a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.booking-links a:hover {
  text-decoration: underline;
}
.complete-trip-button {
  background-color: #28a745;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  transition: background 0.3s ease-in-out;
}
.complete-trip-button:hover {
  background-color: #218838;
}
</style>
