<template>
  <div
    class="details__content__box content"
    v-if="getVaccinationAdvice(country)"
  >
    <h2>{{ $t("vaccinationAdvice") }}</h2>
    <div>
      {{ getVaccinationAdvice(country) }}
      <hr />
      <a style="text-decoration: underline"
         href="https://www.ggdreisvaccinaties.nl/heb-ik-een-vaccinatie-nodig"
         target="_blank">{{ $t("vaccinationAdviceCurrent") }}</a>
    </div>
  </div>
</template>

<script>
import vaccinations from "@/assets/vaccinations.json";

export default {
  props: {
    country: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      vaccinationsData: {}
    };
  },
  created() {
    this.vaccinationsData = vaccinations;
  },
  methods: {
    getVaccinationAdvice(country) {
      return this.vaccinationsData[country];
    }
  }
};
</script>
