<template>
  <div>
    <label for="employeeSearch">
      {{ $t("ReservationForm.find.employee") }}</label
    >
    <div class="dropdown__position--relative" ref="dropdown">
      <div class="control">
        <input
          class="input"
          name="employeeSearch"
          id="employeeSearch"
          v-model="employeeSearch"
          autocomplete="off"
          @keyup="findEmployee"
          type="text"
        />
      </div>
      <div
        class="content dropdown__position--absolute dropdown__content"
        v-if="isDropdownVisible"
      >
        <div class="dropdown__options" v-if="controlHasInput">
          <div
            v-for="found in foundEmployees"
            :key="found.id"
            class="dropdown__item"
          >
            <a @click="selectEmployee(found)">
              {{ found.firstName }} {{ found.lastName }}
              <template v-if="found.emailAddress">
                - {{ found.emailAddress }}
              </template>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownManager from "@/helpers/dropdown.manager.helper"; // Import singleton

export default {
  props: {
    businessUnits: {
      type: Array,
      default: () => []
    },

    preSelectedEmployees: {
      type: String,
      default: ""
    },
    selectedEmployees: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      employeeSearch: "",
      foundEmployees: [],
      employees: [],
      isDropdownVisible: false
    };
  },
  mounted() {
    this.extractEmployees();
    if (this.preSelectedEmployees) {
      let findableIds =
        this.preSelectedEmployees.indexOf(",") > -1
          ? this.preSelectedEmployees.split(",")
          : [this.preSelectedEmployees];
      findableIds.forEach((employeeId) => {
        const employee = this.employees.find((e) => e.id === employeeId);
        this.selectEmployee(employee);
      });
    }
    DropdownManager.register(this);
  },
  beforeUnmount() {
    DropdownManager.unregister(this);
  },
  computed: {
    controlHasInput() {
      return this.employeeSearch.length > 0;
    }
  },
  methods: {
    selectEmployee(employee) {
      const selectedBusinessUnit = this.businessUnits.find((bU) => {
        return (
          bU.employees.find((e) => e.id === employee.id) &&
          bU.name !== "<NOUNIT>"
        );
      });
      this.track("hotelReservationGuestAdd", {
        additionType: "dropdown"
      });
      this.$emit("selectedEmployee", {
        employee: employee,
        businessUnit: selectedBusinessUnit
      });
      this.employeeSearch = "";
      this.foundEmployees = [];
    },
    clearEmployee() {
      this.employeeSearch = "";
    },
    extractEmployees() {
      this.employees = this.businessUnits.map((bU) => bU.employees).flat();
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    },
    findEmployee() {
      const matchString = this.employeeSearch.toLowerCase();
      const selectedEmployeeIds = this.selectedEmployees.map((sE) => sE.id);
      this.foundEmployees = this.employees
        .filter((emp) => {
          const employeeAlreadySelected = selectedEmployeeIds.includes(emp.id);
          if (employeeAlreadySelected) {
            return false;
          }

          return (
            (emp.lastName &&
              emp.lastName.toLowerCase().includes(matchString)) ||
            (emp.firstName &&
              emp.firstName.toLowerCase().includes(matchString)) ||
            (emp.emailAddress &&
              emp.emailAddress.toLowerCase().includes(matchString)) ||
            (emp.id && emp.id.toLowerCase().includes(matchString))
          );
        })
        .slice(0, 20);
      this.isDropdownVisible = this.foundEmployees.length > 0;
      DropdownManager.register(this);
    }
  }
};
</script>

<style lang="scss" scoped></style>
