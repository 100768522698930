/**
 * DropdownManager is a singleton utility that manages dropdown components.
 * It tracks registered dropdown instances, listens for clicks outside of dropdowns,
 * and triggers the `closeDropdown` method on the relevant dropdowns when necessary.
 */
const DropdownManager = {
  // A Set to store all registered dropdown instances
  instances: new Set(),

  /**
   * Registers a dropdown instance with the DropdownManager.
   * Adds the instance to the `instances` set and sets up a global click event listener
   * if this is the first instance being registered.
   *
   * @param {Object} instance - The Vue component instance representing the dropdown.
   *                            Must have a `ref="dropdown"` on the dropdown container
   *                            and a `closeDropdown` method to handle closing the dropdown.
   */
  register(instance) {
    // Ensure `instances` is a Set (safeguard against accidental overwrites)
    if (!(this.instances instanceof Set)) {
      this.instances = new Set();
    }

    // Add the instance to the set
    this.instances.add(instance);

    // If this is the first instance, bind the click handler and add the event listener
    if (this.instances.size === 1) {
      this.boundHandleClickOutside = this.handleClickOutside.bind(this); // Save reference
      document.addEventListener("click", this.boundHandleClickOutside);
    }
  },

  /**
   * Unregisters a dropdown instance from the DropdownManager.
   * Removes the instance from the `instances` set and removes the global click event listener
   * if no instances remain.
   *
   * @param {Object} instance - The Vue component instance to be unregistered.
   */
  unregister(instance) {
    // Remove the instance from the set
    this.instances.delete(instance);

    // If no instances remain, remove the global click event listener
    if (this.instances.size === 0) {
      document.removeEventListener("click", this.boundHandleClickOutside);
    }
  },

  /**
   * Handles global click events to determine if the click occurred outside any registered dropdown.
   * If so, it calls the `closeDropdown` method on the relevant dropdown instance.
   *
   * @param {Event} event - The click event triggered on the document.
   */
  handleClickOutside(event) {
    // Ensure `instances` is a Set (safeguard against accidental overwrites)
    if (!(this.instances instanceof Set)) {
      this.instances = new Set();
      return;
    }

    // Iterate through all registered dropdown instances
    for (const dropdown of this.instances) {
      // Check if the click occurred outside the dropdown container
      if (
        dropdown.$refs?.dropdown &&
        !dropdown.$refs.dropdown.contains(event.target)
      ) {
        // Call the `closeDropdown` method on the dropdown instance. CloseDropdown is found in the partials themselves.
        dropdown.closeDropdown();
      }
    }
  }
};

export default DropdownManager;
