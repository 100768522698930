<template>
  <div>
    <label for="businessUnitSearch">{{
      tbu("ReservationForm.findBusinessUnit")
    }}</label>
    <div class="dropdown__position--relative" ref="dropdown">
      <div class="control">
        <input
          class="input"
          name="businessUnitSearch"
          id="businessUnitSearch"
          v-model="businessUnitSearch"
          @keyup="findBusinessUnit"
          type="text"
        />
      </div>
      <div
        class="content dropdown__position--absolute dropdown__content"
        v-if="isDropdownVisible"
      >
        <div class="dropdown__options" v-if="controlHasInput">
          <div
            v-for="found in foundBusinessUnits"
            :key="found.id"
            class="dropdown__item"
          >
            <a @click="selectBusinessUnit(found)">{{ found.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownManager from "@/helpers/dropdown.manager.helper"; // Import singleton

export default {
  props: {
    preselectedBusinessUnitId: {
      type: String,
      default: null
    },
    businessUnits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      businessUnitSearch: "",
      foundBusinessUnits: [],
      businessUnit: null,
      isDropdownVisible: false
    };
  },

  computed: {
    controlHasInput() {
      return this.businessUnitSearch.trim() !== "";
    }
  },

  mounted() {
    if (this.preselectedBusinessUnitId) {
      const preslected = this.businessUnits.find(
        (bU) => bU.id === this.preselectedBusinessUnitId
      );
      if (preslected !== null) {
        this.selectBusinessUnit(preslected);
      }
    } else {
      this.findBusinessUnit();
    }
    DropdownManager.register(this);
  },
  beforeUnmount() {
    DropdownManager.unregister(this);
  },
  methods: {
    selectBusinessUnit(businessUnit) {
      this.businessUnitSearch = "";
      this.foundBusinessUnits = [];
      this.$emit("selectedBusinessUnit", { businessUnit: businessUnit });
    },
    findBusinessUnit() {
      const matchString = this.businessUnitSearch.toLowerCase();
      this.foundBusinessUnits = this.businessUnits.filter(
        (bU) =>
          bU.name.toLowerCase().includes(matchString) && bU.name !== "<NOUNIT>"
      );
      this.isDropdownVisible = this.foundBusinessUnits.length > 0;
      DropdownManager.register(this);
      //.slice(0, 25);
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
