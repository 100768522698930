<template>
  <div class="column is-full" v-if="reservation && isVisibleFromFilter">
    <div class="card mx-5" :data-id="reservation.requestId">
      <header class="card-header">
        <p class="card-header-title">
          {{ getHotelNameWithCity(reservation.hotel) }}
        </p>
        <p class="card-header-title is-justify-content-center">
          <i class="fa fa-person"></i>
          &nbsp;{{ getReservationGuests() }}
        </p>

        <p class="card-header-title is-justify-content-flex-end">
          <span>
            <i :class="getIcon(reservation.reservationType)"></i>
            {{ reservation.reservationType ?? "Onbekend" }}
          </span>
        </p>

        <p class="card-header-title is-justify-content-flex-end">
          <span>
            <i class="fa fa-moon"> </i>
            {{ getReservationNights() }}
            |
            {{ getReservationRangeAsString() }}
          </span>
        </p>
      </header>
      <div class="card-content">
        <div class="columns">
          <div class="column content">
            <table>
              <tbody>
                <tr v-if="getReference()">
                  <th>{{ $t("projectNumber") }}</th>
                  <td>{{ getReference() }}</td>
                </tr>
                <tr v-if="reservation.booker">
                  <th>{{ $t("booker") }}</th>
                  <td>
                    {{ reservation?.booker.firstName }}
                    {{ reservation?.booker.lastName }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("bookingDate") }}</th>
                  <td>
                    {{ formatDate(reservation.bookingDate) }}
                  </td>
                </tr>
                <tr v-if="reservation?.businessUnit?.name">
                  <th>{{ getBusinessUnitLabel() }}</th>
                  <td>{{ reservation.businessUnit.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t("hotelAddress") }}</th>
                  <td>
                    {{ reservation.hotel.street }} <br />
                    {{ reservation.hotel.postalCode }}
                    {{ reservation.hotel.city }}<br />
                    {{ reservation.hotel.country }}
                  </td>
                </tr>
                <tr
                  v-for="field in reservation.customFields"
                  v-bind:key="field.name"
                >
                  <th>
                    {{ getFieldNameTranslation(field) }}
                  </th>
                  <td>
                    {{ getFieldValueTranslation(field, field.value) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column content">
            <template v-if="cancellationGuest">
              <h5 style="display: block; width: 100%">
                {{
                  $t("requestCancellation", {
                    firstname: cancellationGuest.firstName,
                    lastname: cancellationGuest.lastName
                  })
                }}

                <button
                  class="button is-warning is-small is-right"
                  style="float: right"
                  @click="cancellationGuest = null"
                >
                  {{ $t("back") }}
                </button>
              </h5>

              <table width="100%">
                <tbody>
                  <tr
                    v-for="d in getCancellableDates(cancellationGuest)"
                    :key="d.date"
                  >
                    <td colspan="2">
                      <label :for="d.id">
                        <input
                          type="checkbox"
                          v-model="cancellingDates"
                          v-if="
                            getCancellableDates(cancellationGuest).length > 1
                          "
                          :value="d.id"
                          :id="d.id"
                          checked="true"
                        />

                        {{ getFormattedDate(d.date) }}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <button
                        :disabled="cancellingDates.length == 0"
                        v-if="getCancellableDates(cancellationGuest).length > 1"
                        class="button is-danger is-small"
                        @click="cancelSelected()"
                      >
                        {{ $t("requestCancellationSelected") }}
                      </button>
                    </td>
                    <td>
                      <button
                        class="button is-danger is-small"
                        @click="cancelAll()"
                      >
                        {{ $t("requestCancellationAll") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <table>
                <thead>
                  <tr>
                    <th>{{ $t("guest") }}</th>
                    <th>{{ $t("stay") }}</th>
                    <th>{{ $t("costs") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="g in getGuests()"
                    v-bind:key="g.name"
                    :class="{ 'all-cancelled': g.allDatesCancelled }"
                  >
                    <td width="35%">
                      <b>{{ g.firstName }} {{ g.lastName }}</b>
                    </td>
                    <td>
                      {{ getDateRangeForEmployee(g) }}
                    </td>
                    <td width="20%">
                      {{ getTotalPriceForEmployee(g) }}
                    </td>
                    <td>
                      <i
                        :title="
                          $t('requestCancellation', {
                            firstname: g.firstName,
                            lastname: g.lastName
                          })
                        "
                        class="fa-solid fa-delete-left is-danger"
                        @click="requestCancellation(g)"
                        style="cursor: pointer; color: #f14668"
                        v-if="g.hasCancallabeDates"
                      ></i>
                      <i
                        :title="
                          g.allDatesCancelled
                            ? $t('allDatesCancelled')
                            : $t('hasCancelledDates')
                        "
                        class="fa-solid fa-ban"
                        style="cursor: pointer"
                        v-if="g.hasCancelledDates"
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <b>{{ $t("total") }}</b>
                    </td>
                    <td width="20%" colspan="2">
                      {{ getTotalPriceForEmployees() }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <br />
          </div>
        </div>
      </div>
      <div class="card-footer is-justify-content-flex-end">
        <div class="buttons has-addons is-right">
          <template v-if="hasKnownhotel">
            <label class="px-6">
              <input type="checkbox" v-model="rebookOptions.sameGuest" />
              {{ $t("rebook.sameGuest") }}
            </label>
            <label class="px-6" v-if="reservation.businessUnit?.id">
              <input type="checkbox" v-model="rebookOptions.sameBusinessUnit" />
              {{ tbu("rebook.sameBusinessUnit") }}
            </label>

            <label class="px-6" v-if="isFutureReservation">
              <input type="checkbox" v-model="rebookOptions.sameDate" />
              {{ $t("rebook.sameDate") }}
            </label>

            <button class="button is-info" @click="rebook()">
              {{ $t("rebook.rebook") }}
            </button>
            <div
              class="is-warning button"
              v-if="rebookOptions.sameDate && rebookOptions.sameGuest"
            >
              {{ $t("rebook.doubleWarning") }}
            </div>
          </template>
          <template v-else>
            <div class="is-warning button">
              <i
                style="color: orange"
                class="fa-solid fa-triangle-exclamation"
              ></i>
              &nbsp;
              {{ $t("reservationoverview.externalBooking") }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showConfirmation }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("areYouSure") }}</p>
          <button
            @click="
              confirmationDates = [];
              cancellingDates = [];
              showConfirmation = false;
            "
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          {{ $t("confirmationTextCancellationRequest") }}
        </section>
        <footer class="modal-card-foot">
          <button
            @click="confirmRequestCancellation()"
            class="button is-warning"
          >
            {{ $t("confirmCancellationRequest") }}
            <img
              width="50"
              height="60"
              src="@/assets/images/loader.svg"
              v-if="isCancelling"
            />
          </button>
          <button
            @click="
              confirmationDates = [];
              cancellingDates = [];
              showConfirmation = false;
            "
            class="button"
          >
            {{ $t("noGoBack") }}
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import ReservationHelper from "@/helpers/reservation.helper.js";

import moment from "moment";

export default {
  name: "ReservationItem",
  data() {
    return {
      rebookOptions: {
        sameGuest: false,
        sameDate: false,
        sameBusinessUnit: false
      },
      cancellingDates: [],
      cancellationGuest: null,
      showConfirmation: false,
      confirmationDates: [],
      isCancelling: false
    };
  },
  props: {
    reservation: {
      type: Object,
      default: () => {}
    },
    reservationType: {
      type: String,
      default: "Hotel"
    },
    visibleItems: {
      type: String,
      default: "ALL"
    }
  },
  methods: {
    getCancellableDates(guest) {
      const dates = this.reservation.guestsWithDates.find((gwd) => {
        return gwd.employee.id == guest.id;
      }).dates;
      return dates.filter(ReservationHelper.dateIsCancellable);
    },
    dateIsCancellable(d) {
      return ReservationHelper.dateIsCancellable(d);
    },
    requestCancellation(guest) {
      this.cancellationGuest = guest;
    },
    cancelSelected() {
      this.cancelDates(this.cancellingDates);
    },
    cancelAll() {
      let allDates = this.getCancellableDates(this.cancellationGuest).map(
        (d) => d.id
      );
      this.cancelDates(allDates);
    },
    cancelAllEmployees() {
      let dateIds = [];
      this.reservation.guestsWithDates.forEach((gwd) => {
        let allDates = this.getCancellableDates(gwd.employee).map((d) => d.id);
        dateIds = dateIds.concat(allDates);
      });
      this.cancelDates(dateIds);
    },
    cancelDates(dates) {
      this.confirmationDates = dates;
      this.showConfirmation = true;
    },
    cancelCancellation() {
      this.confirmationDates = [];
      this.cancellingDates = [];
      this.showConfirmation = false;
      this.$emit("cancelledItems");
    },
    async confirmRequestCancellation() {
      this.isCancelling = true;
      await ReservationHelper.cancelReservations(this.confirmationDates);
      this.isCancelling = false;
      this.cancelCancellation();
    },
    rebook() {
      this.$emit("rebook", {
        reservation: this.reservation,
        dateRange: this.reservationDateRange,
        adults: this.reservation.guestsWithDates.length,
        employees: this.reservation.guestsWithDates
          .map((gwd) => gwd.employee.id)
          .join(","),
        rebookOptions: this.rebookOptions
      });
    },
    getReference() {
      try {
        return this.reservation.customFields.find(
          (f) => f.name == "Reference__c"
        ).value;
      } catch (e) {
        return null;
      }
    },
    getTotalPriceForEmployee(employee) {
      let reservation = this.getReservationForEmployee(employee);
      let totalPrice = reservation.dates.reduce(
        (prev, current) => (prev += current.costs),
        0
      );
      return this.toPriceLabel(totalPrice);
    },
    getTotalPriceForEmployees() {
      let reservations = this.reservation.guestsWithDates;
      let total = 0;
      reservations.forEach((b) => {
        total += b.dates.reduce((prev, current) => (prev += current.costs), 0);
      });
      return this.toPriceLabel(total);
    },
    getReservationForEmployee(employee) {
      return this.reservation.guestsWithDates.find(
        (gwd) => gwd.employee.id === employee.id
      );
    },
    getDateRangeForEmployee(employee) {
      let reservation = this.getReservationForEmployee(employee);
      let { startDate, endDate } = this.getDateRangeForReservation(reservation);
      return (
        this.formatMoment(new moment(startDate)) +
        " - " +
        this.formatMoment(new moment(endDate).add("1", "days"))
      );
    },
    getGuests() {
      return this.reservation.guestsWithDates.map((gwd) => gwd.employee);
    },
    getReservationGuests() {
      let guests = this.getGuests();

      return guests.map((emp) => `${emp.firstName} ${emp.lastName}`).join(", ");
    },
    getReservationNights() {
      try {
        return this.reservation.guestsWithDates[0].dates.length;
      } catch (e) {
        return 1;
      }
    },
    getReservationRangeAsString() {
      let { startDate, endDate } = this.reservationDateRange;
      startDate = new moment(startDate);
      let formatted = startDate.format("D MMMM yyyy");
      return formatted;
    },
    getFormattedDate(date) {
      return new moment(date).format("ddd D MMMM yyyy");
    },
    getDateRangeForReservation(reservation) {
      let startDate = null,
        endDate = null;
      const dates = reservation.dates;
      startDate = dates[0].date;

      endDate = dates[dates.length - 1].date;
      return { startDate, endDate };
    },
    getIcon(type) {
      const icons = {
        Hotel: "fa fa-building",
        Vlucht: "fa fa-plane",
        Vergaderarrangement: "fa fa-car"
      };
      return icons[type] || "fa fa-question-circle";
    }
  },
  computed: {
    hasKnownhotel() {
      return this.reservation.hotel.id;
    },
    isVisibleFromFilter() {
      let filter = this.visibleItems;
      switch (filter) {
        case "FUTURE":
          return this.isFutureReservation;
        case "CURRENT":
          return this.isNowHappening;
        default:
          return true;
      }
    },
    reservationDateRange() {
      let { startDate, endDate } = this.getDateRangeForReservation(
        this.reservation.guestsWithDates[0]
      );
      startDate = moment(startDate);
      endDate = moment(endDate);
      return { startDate, endDate };
    },
    isFutureReservation() {
      let { startDate } = this.reservationDateRange;

      let startsAfterToday = startDate.isAfter();
      return startsAfterToday;
    },
    isNowHappening() {
      try {
        let { startDate, endDate } = this.reservationDateRange;
        let today = moment();
        let startsBeforeToday = startDate.isSameOrBefore(today, "day");
        let endsAfterToday = endDate.add(1, "day").isSameOrAfter(today, "day");

        return startsBeforeToday && endsAfterToday;
      } catch (e) {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.all-cancelled {
  text-decoration: line-through;
}
</style>
