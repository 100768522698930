<template>
  <div class="details__content__box" v-if="visaRequired">
    <h5 class="title is-5">{{ visaMessage }}</h5>
    <p class="info-text">
      {{ $t("visainfo.message") }}
      <a
        href="https://www.nederlandwereldwijd.nl/reisadvies"
        target="_blank"
        class="link"
      >
        {{ $t("visainfo.linkText") }} </a
      >.
    </p>
  </div>
</template>

<script>
import { fetchVisaInfo } from "@/helpers/common.helper";
import countries from "@/assets/countries.json"; // Import the countries JSON

export default {
  props: {
    country: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visaInfo: null,
      countryId: null // Store the country ID
    };
  },
  computed: {
    visaRequired() {
      return this.visaInfo?.hasVisa || false;
    },
    visaMessage() {
      if (!this.visaInfo) return "";
      return this.$i18n.locale === "nl"
        ? this.visaInfo.visaTextNL
        : this.visaInfo.visaTextEN;
    }
  },
  methods: {
    async loadVisaInfo() {
      try {
        // Find the country ID based on the country name
        const country = countries.find(
          (c) => c.name.toLowerCase() === this.country.toLowerCase()
        );
        if (!country) {
          console.error(
            `Country "${this.country}" not found in countries.json`
          );
          return;
        }
        this.countryId = country.id;

        // Fetch visa information using the country ID
        this.visaInfo = await fetchVisaInfo(this.countryId);
      } catch (error) {
        console.error("Error loading visa information:", error);
      }
    }
  },
  watch: {
    countryName: "loadVisaInfo" // Watch for changes in countryName
  },
  mounted() {
    this.loadVisaInfo();
  }
};
</script>

<style scoped>
.info-text {
  font-size: 0.875rem;
  color: #333;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
</style>
