<template>
  <div class="details reservationoverview">
    <div class="details__title px-5" v-if="transport">
      <h1>{{ $t("transportThankYouPage.PageHeading") }}</h1>
    </div>
    <div class="container mt-6">
      <div class="columns is-multiline">
        <div class="column is-full has-text-centered" v-if="isLoading">
          <h3 class="title is-3">
            {{ $t("transportThankYouPage.LoadingMessage") }}
          </h3>
          <br />
          <img width="50" height="60" src="@/assets/images/loader.svg" />
        </div>
        <div class="column is-full has-text-centered" v-else-if="error">
          <p class="has-text-danger">{{ error }}</p>
        </div>
        <div class="column is-full" v-else-if="transport">
          <div class="columns is-multiline">
            <div class="column is-half-tablet is-full-mobile">
              <div class="section">
                <h2 class="section-title">
                  🌍 {{ $t("transportThankYouPage.DepartureFlightHeading") }}
                </h2>
                <div
                  v-if="transport.flights && transport.flights.length > 0"
                  class="flight-details"
                >
                  <p>
                    <strong>{{ $t("transportThankYouPage.Departure") }}</strong>
                    {{ transport.flights[0].departureAirport.name }} ({{
                      transport.flights[0].departureAirport.iataCode
                    }})
                  </p>
                  <p>
                    <strong>{{ $t("transportThankYouPage.Arrival") }}</strong>
                    {{ transport.flights[0].arrivalAirport.name }} ({{
                      transport.flights[0].arrivalAirport.iataCode
                    }})
                  </p>
                  <p>
                    <strong>{{
                      $t("transportThankYouPage.DepartureDate")
                    }}</strong>
                    {{ formatDate(transport.flights[0].departureDate) }}
                  </p>
                  <p>
                    <strong>{{
                      $t("transportThankYouPage.DepartureTime")
                    }}</strong>
                    {{ transport.flights[0].departureTimeOfDay }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half-tablet is-full-mobile">
              <div class="section">
                <h2 class="section-title">
                  🌍 {{ $t("transportThankYouPage.ReturnFlightHeading") }}
                </h2>
                <div
                  v-if="transport.flights && transport.flights.length > 1"
                  class="flight-details"
                >
                  <p>
                    <strong>{{ $t("transportThankYouPage.Departure") }}</strong>
                    {{ transport.flights[lastTripLeg].departureAirport.name }}
                    ({{
                      transport.flights[lastTripLeg].departureAirport.iataCode
                    }})
                  </p>
                  <p>
                    <strong>{{ $t("transportThankYouPage.Arrival") }}</strong>
                    {{ transport.flights[lastTripLeg].arrivalAirport.name }} ({{
                      transport.flights[lastTripLeg].arrivalAirport.iataCode
                    }})
                  </p>
                  <p>
                    <strong>{{
                      $t("transportThankYouPage.DepartureDate")
                    }}</strong>
                    {{
                      formatDate(transport.flights[lastTripLeg].departureDate)
                    }}
                  </p>
                  <p>
                    <strong>{{
                      $t("transportThankYouPage.DepartureTime")
                    }}</strong>
                    {{ transport.flights[lastTripLeg].departureTimeOfDay }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-full">
            <div class="section full-width">
              <h2 class="section-title">
                ✈️ {{ $t("transportThankYouPage.FlightInfoHeading") }}
              </h2>
              <p>
                <strong>{{ $t("transportThankYouPage.TravelClass") }}</strong>
                {{ transport.travelClass }}
              </p>
              <p>
                <strong>{{ $t("transportThankYouPage.HoldLuggage") }}</strong>
                {{ transport.holdLuggage ? "✅" : "❌" }}
              </p>
              <p>
                <strong>{{
                  $t("transportThankYouPage.ChangesAllowed")
                }}</strong>
                {{ transport.changesAllowed ? "✅" : "❌" }}
              </p>
              <p>
                <strong>{{ $t("transportThankYouPage.Notes") }}</strong>
                {{ transport.notes || "No notes provided" }}
              </p>
            </div>
          </div>
          <div class="column is-full">
            <div class="section full-width">
              <h2 class="section-title">
                📩 {{ $t("thankYouPage.InfoHeading") }}
              </h2>
              <p>{{ $t("transportThankYouPage.InfoParagraph1") }}</p>
              <br />
              <p>
                {{
                  $t("transportThankYouPage.InfoParagraph2", { email: email })
                }}
              </p>
              <br />
              <ul class="booking-links">
                <li>
                  <a href="/"
                    >🏢 {{ $t("transportThankYouPage.HotelsHref") }}</a
                  >
                </li>
                <li>
                  <a href="/meetingroomreservation"
                    >💼 {{ $t("transportThankYouPage.MeetingHref") }}</a
                  >
                </li>
                <li>
                  <a href="/flightform"
                    >✈️ {{ $t("thankYouPage.FlightsHrefs") }}</a
                  >
                </li>
                <li>
                  <a href="/trainform">🚆 {{ $t("thankYouPage.TrainHref") }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="column is-full has-text-centered">
            <a href="/" class="complete-trip-button"
              >✅ {{ $t("transportThankYouPage.CompleteTransport") }}</a
            >
          </div>
        </div>
        <div class="column is-full has-text-centered" v-else>
          <p>{{ $t("transportThankYouPage.NoTransportBookingFound") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReservationHelper from "@/helpers/reservation.overview.helper";
import moment from "moment";

export default {
  data() {
    return {
      transport: null,
      isLoading: true,
      email: "zakelijk@lookingforbooking.com",
      lastTripLeg: 1
    };
  },
  async created() {
    await this.fetchTransport();
  },
  methods: {
    async fetchTransport() {
      try {
        this.isLoading = true;
        this.error = null;
        const response = await ReservationHelper.getTransport();

        this.lastTripLeg = response.reservationList.length - 1;

        if (
          response &&
          response.reservationList &&
          response.reservationList.length > 0
        ) {
          this.transport = response.reservationList[0];
        } else {
          this.error = "No transport booking found.";
        }
      } catch (err) {
        this.error = "Failed to load transport booking. Please try again.";
        console.error("Error fetching transport:", err);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format("DD-MM-YYYY")
        : moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
  }
};
</script>

<style scoped>
.details__title {
  background: url("@/assets/images/flight-bg.webp") center center no-repeat;
  background-size: cover;
  text-align: center;
  padding: 40px 20px;
  color: white;
  border-radius: 10px;
}

.details__title h1 {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.details__title .subtitle {
  font-size: 18px;
  color: #ddd;
}

.details.transportoverview {
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: start;
}

.full-width {
  grid-column: span 2;
}

.section {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.section:hover {
  transform: translateY(-2px);
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

.details__content__box p {
  font-size: 16px;
  color: #444;
  line-height: 1.6;
  margin-bottom: 8px;
}

.details__content__box strong {
  color: #222;
  font-weight: 600;
}

@media (max-width: 768px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    width: 100%;
  }

  .section {
    width: 92%;
    max-width: 400px;
    margin: 0 auto;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .details__content__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .full-width {
    width: 92%;
    max-width: 400px;
    margin: 0 auto 10px auto;
  }

  .details__title {
    padding: 20px 10px;
  }

  .details__title h1 {
    font-size: 22px;
  }

  .section-title {
    font-size: 18px;
  }

  .details__content__box p {
    font-size: 14px;
  }
}

.booking-links {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.booking-links li {
  margin-bottom: 8px;
}

.booking-links a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.booking-links a:hover {
  text-decoration: underline;
}

.complete-trip-button {
  background-color: #28a745;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  transition: background 0.3s ease-in-out;
}

.complete-trip-button:hover {
  background-color: #218838;
}
</style>
