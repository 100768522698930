<template>
  <div class="column is-full">
    <div class="card mx-5" :data-id="reservation.requestId">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t("transportThankYouPage.Flight") }}
          {{ reservation.flightNumber }}
        </p>
        <p class="card-header-title is-justify-content-flex-end">
          <i class="fa fa-plane"></i> &nbsp;
          {{ $t("transportThankYouPage.Flight") }}

          <!-- {{            reservation.flightClass          }}  - -->
          ({{ flightTypeLabel }})
        </p>
        <p class="card-header-title is-justify-content-flex-end">
          <span>
            <i class="fa fa-calendar"> </i>
            {{ formatDateLabel(reservation.flights[0].departureDate) }}
          </span>
        </p>
      </header>

      <div class="card-content">
        <table>
          <tbody>
            <tr>
              <th>Route</th>
              <td>
                <ul>
                  <li
                    v-for="(flight, index) in reservation.flights"
                    :key="index"
                  >
                    🌎 {{ flight.departureAirport.name }} -
                    {{ formatDateLabel(flight.departureDate) }}
                    <!-- (                      {{
                      $t(
                        `ReservationForm.${flight.departureTimeOfDay.toLowerCase()}`
                      )
                    }}) -->
                    → 🌎 {{ flight.arrivalAirport.name }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t("transportThankYouPage.HoldLuggage") }}</th>
              <td>{{ reservation.holdLuggage ? "✅" : "❌" }}</td>
            </tr>
            <tr>
              <th>Wijzigen mogelijk</th>
              <td>{{ reservation.changesAllowed ? "✅" : "❌" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reservation: Object
  },
  computed: {
    flightTypeLabel() {
      const flightCount = this.reservation.flights.length;
      if (flightCount === 1) {
        return "enkele reis";
      } else if (flightCount === 2) {
        return "retourvlucht";
      } else {
        return "meerdere steden vlucht";
      }
    }
  }
};
</script>
