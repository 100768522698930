<template>
  <div
    @click="gotoCityReservation()"
    class="results-list__wrapper"
    v-show="location"
  >
    <div class="results-list__wrapper--content">
      <h4 class="title is-4">
        <template v-if="hotelCount > 0">
          {{ $t("cityReservation.notFound", { name: location?.name }) }}
        </template>
        <template v-if="hotelCount === 0">
          {{ $t("cityReservation.noneFound", { name: location?.name }) }}
        </template>
      </h4>
      <p v-if="hotelCount > 0">
        {{ $t("cityReservation.placeRequest") }}
      </p>
      <p v-if="hotelCount === 0">
        {{ $t("cityReservation.placeRequestNoneFound") }}
      </p>
    </div>
    <div class="results-list__wrapper--price">
      <div class="results-list__item__button" style="text-align: right">
        <a class="c-button--small--results" @click="gotoCityReservation()">
          {{ $t("cityReservation.place") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    gotoCityReservation() {
      this.$emit("gotoCityReservation");
    }
  },
  watch: {},
  name: "CityReservation",
  emits: ["gotoCityReservation"],
  props: {
    hotelCount: {
      type: Number,
      default: 1
    },
    hotelAvailableCount: {
      type: Number,
      default: 1
    },
    location: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
