<template>
  <div class="navbar-end">
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("areYouSure") }}</p>
          <button
            class="delete"
            @click="showModal = false"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          {{ $t("loginStatus.wantToLogOut") }}
        </section>
        <footer class="modal-card-foot">
          <button @click="logout()" class="button is-warning">
            {{ $t("loginStatus.yesLogOut") }}
          </button>
          <button @click="showModal = false" class="button">
            {{ $t("no") }}
          </button>
        </footer>
      </div>
    </div>
    <div class="dropdown is-right" :class="{ 'is-active': isActive }">
      <div class="dropdown-trigger" ref="dropdownTrigger" @click="toggleActive">
        <button
          class="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span class="is-hidden-touch"
            >{{ isLoggedIn ? (fullName ?? userName) : "Log in" }} {{ env }}
          </span>
          <span class="is-hidden-desktop">
            <template v-if="isLoggedIn">
              <i class="fas fa-user"></i>
            </template>
            <template v-else> Login </template>
          </span>
          <span class="icon is-small">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a class="dropdown-item" v-if="env != ''" :href="envSwitchUrl">
            {{ envSwitchText }}
          </a>
          <a
            class="dropdown-item"
            @click="setLocale('en')"
            :class="{ 'is-active': $i18n.locale == 'en' }"
          >
            <img src="@/assets/images/icons/gb.png" width="20" />
            English
          </a>
          <a
            :class="{ 'is-active': $i18n.locale == 'nl' }"
            class="dropdown-item"
            @click="setLocale('nl')"
          >
            <img src="@/assets/images/icons/nl.png" width="20" />
            Nederlands
          </a>
          <template v-if="isLoggedIn">
            <hr class="dropdown-divider is-hidden-desktop" />
            <a class="dropdown-item is-hidden-desktop">
              {{ fullName ?? userName }}
            </a>
            <hr class="dropdown-divider" />
            <a href="#" @click="refreshCompanyInfo()" class="dropdown-item">
              Ververs data
            </a>
            <a
              href="/userprofile"
              class="dropdown-item"
              v-if="enableFlightRequests || !isProd()"
            >
              {{ $t("profile.metamenuLink") }}
            </a>
            <hr class="dropdown-divider" v-if="managementPortalUrl" />
            <a
              v-if="managementPortalUrl"
              :href="managementPortalUrl"
              target="_blank"
              class="dropdown-item"
            >
              Managementportal
            </a>

            <hr class="dropdown-divider" />

            <a href="#" @click="showModal = true" class="dropdown-item"
              >{{ $t("loginStatus.logout") }}
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHelper from "@/helpers/common.helper.js";

export default {
  data() {
    return {
      showModal: false,
      isActive: false,
      isLoggedIn: false,
      env: "",
      envSwitchUrl: "",
      envSwitchText: "",
      userName: "",
      enableFlightRequests: false
    };
  },
  mounted() {
    this.checkLoginStatus();
    document.body.addEventListener("click", (e) => {
      this.isActive = false;
    });
  },
  watch: {
    $route(to, from) {
      this.checkLoginStatus();
    }
  },
  computed: {
    fullName() {
      let companyInfo = JSON.parse(localStorage.companyInfo);

      return companyInfo?.fullName ? companyInfo?.fullName : null;
    },
    managementPortalUrl() {
      let companyInfo = JSON.parse(localStorage.companyInfo);
      return companyInfo?.managementPortalUrl
        ? companyInfo?.managementPortalUrl
        : null;
    }
  },
  methods: {
    checkLoginStatus() {
      this.setEnvLabel();
      let userData = CommonHelper.isLoggedIn();
      if (userData) {
        let companyInfo = JSON.parse(localStorage.companyInfo);

        this.enableFlightRequests = companyInfo?.enableFlightRequests ?? true;

        this.isLoggedIn = true;
        this.userName = userData.userName;
      } else {
        this.isLoggedIn = false;
      }
    },
    setEnvLabel() {
      // let env = CommonHelper.getApiEnv();
      this.env = "";

      return; // Disable this feature for now

      // if (window.location.origin === "https://portal.lookingforbooking.com") {
      //   return;
      // }
      // if (env !== "prod") {
      //   this.env = ` - ${env}`;
      //   if (env === "test") {
      //     this.envSwitchUrl = window.location.origin + "/?env=staging";
      //     this.envSwitchText = "Switch naar staging";
      //   } else {
      //     this.envSwitchUrl = window.location.origin + "/?env=test";
      //     this.envSwitchText = "Switch naar test";
      //   }
      // }
    },
    setLocale(locale) {
      localStorage.locale = locale;
      this.$i18n.locale = locale;

      setTimeout(() => window.location.reload(), 500);
    },
    toggleActive(e) {
      this.isActive = !this.isActive;
      e.stopPropagation();
    },
    async refreshCompanyInfo() {
      await CommonHelper.fetchCompanyInfo();
      localStorage.removeItem("theme");
      await this.injectCustomCss();
    },
    logout() {
      CommonHelper.invalidateSession();
      this.showModal = false;
      setTimeout(() => {
        this.$router.go("/");
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped></style>
