<template>
  <div id="wrapper">
    <header class="header">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <template v-if="isLoggedIn">
            <router-link class="navbar-item logo" :to="{ name: 'landingPage' }">
              &nbsp;
            </router-link>
          </template>
          <template v-else>
            <a class="navbar-item logo logo--not-logged-in"> &nbsp; </a>
          </template>
          <template v-if="isLoggedIn">
            <a
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="l4bNavBar"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </template>
        </div>

        <div id="l4bNavBar" class="navbar-menu">
          <div class="navbar-start">
            <template v-if="isLoggedIn">
              <a class="navbar-item" href="/">
                {{ $t("nav.hotels") }}
              </a>

              <a
                v-if="enableMeetingRequests"
                class="navbar-item"
                href="/meetingroomreservation"
              >
                {{ $t("nav.meetingReservation") }}
              </a>
              <a
                v-if="enableFlightRequests || !isProd()"
                class="navbar-item"
                href="/flightform"
              >
                {{ $t("nav.flightForm") }}
              </a>
              <a
                v-if="enableTrainRequests || !isProd()"
                class="navbar-item"
                href="/trainform"
              >
                {{ $t("nav.trainform") }}
              </a>
              <a class="navbar-item" href="/reservation-overview">
                {{ $t("nav.bookingOverview") }}
              </a>
              <div
                class="navbar-item contact-container"
                @mouseenter="showContact = true"
                @mouseleave="showContact = false"
              >
                <span @click="showContact = !showContact">
                  {{ $t("nav.contact") }}
                </span>
                <div v-if="showContact" class="contact-popup">
                  <p class="email">
                    ✉️
                    <a href="mailto:zakelijk@lookingforbooking.com"
                      >zakelijk@lookingforbooking.com</a
                    >
                  </p>
                  <hr />
                  <p>
                    📞 {{ $t("nav.contactPhone")
                    }}<strong
                      ><a href="tel:+31503965050"> +31 (0)50-3695050</a></strong
                    >
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </nav>

      <MetaMenu></MetaMenu>
    </header>
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import MetaMenu from "./components/shared/MetaMenu.vue";
import CommonHelper from "@/helpers/common.helper.js";
import moment from "moment";
export default {
  components: { MetaMenu },
  name: "App",
  data() {
    return {
      isLoggedIn: false,
      userName: "",
      fullName: "",
      enableMeetingRequests: false,
      enableFlightRequests: false,
      enableTrainRequests: false,
      showContact: false
    };
  },
  watch: {
    $route() {
      this.checkLoginStatus();
    }
  },
  async created() {
    moment.locale(this.languageIso);
    await this.checkLoginStatus();
    if (this.isLoggedIn) {
      this.injectCustomCss();
      let companyInfo = await CommonHelper.getCompanyInfo();
      this.fullName = companyInfo?.fullName ?? "";
      this.enableMeetingRequests = companyInfo?.enableMeetingRequests ?? true;
      this.enableFlightRequests = companyInfo?.enableFlightRequests ?? true;
      this.enableTrainRequests = companyInfo?.enableTrainRequests ?? true;
    }
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );

    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  },

  methods: {
    async checkLoginStatus() {
      let userData = CommonHelper.isLoggedIn();
      if (userData) {
        this.isLoggedIn = true;
        this.userName = userData.userName;
        let companyInfo = await CommonHelper.getCompanyInfo();

        this.enableMeetingRequests = companyInfo.enableMeetingRequests ?? true;
        this.enableFlightRequests = companyInfo.enableFlightRequests ?? true;
        this.enableTrainRequests = companyInfo.enableTrainRequests ?? true;
      } else {
        this.isLoggedIn = false;
      }
    }
  }
};
</script>

<style lang="scss">
.logo--big {
  position: absolute;
  right: 0;
  height: 60px;
  top: 50%;
  margin-top: -30px;
}
</style>
