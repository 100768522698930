<template>
  <div class="column is-full">
    <div class="card mx-5" :data-id="reservation.requestId">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t("nav.meetingReservation") }}: {{ reservation.location }}
          {{ reservation.hotel?.name }}
        </p>

        <p class="card-header-title is-justify-content-center">
          <i class="fa fa-person"></i>
          &nbsp; {{ $t("searchFilters.numberOfPersons") }}
          {{ reservation.numberOfGuests }}
        </p>

        <p class="card-header-title is-justify-content-flex-end">
          <i class="fa fa-landmark"></i>
          &nbsp; {{ $t("nav.meetingReservation") }}
        </p>

        <p class="card-header-title is-justify-content-flex-end">
          <span>
            <i class="fa fa-calendar"> </i>
            {{ formatDateLabel(reservation.endDate) }}
          </span>
        </p>
      </header>
      <div class="card-content">
        <table>
          <tbody>
            <tr>
              <th>Hotel</th>
              <td>
                {{
                  reservation.hotel
                    ? `${reservation.hotel.name}, ${reservation.hotel.street}`
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th>Tijden</th>
              <td>{{ reservation.startTime }} - {{ reservation.endTime }}</td>
            </tr>
            <tr>
              <th>Lunch</th>
              <td>
                {{ reservation.needsLunch ? "✅" : "❌" }}
              </td>
            </tr>

            <tr>
              <th>Contactpersoon</th>
              <td>
                {{ reservation.contactName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reservation: Object
  }
};
</script>
